<script>
import auditController from '@/auditController'
import Search from '@/pages/search/components/search'
import { GlobalSearch } from '@/pages/search/controllers'

export default {
  extends: Search,
  activated () {
    this.$store.commit('setModule', {
      name: ''
    })
  },
  computed: {
    scopeModes () {
      return [GlobalSearch.GLOBAL_MODE]
    }
  },
  data () {
    return {
      auditController
    }
  },
  watch: {
    'auditController.dataAudit': {
      immediate: true,
      handler (n, o) {
        if (n || o) {
          this.search.setDocumentType().setAudit(n).clearText().execute()
        }
      }
    }
  }
}
</script>
